<template>
  <ais-instant-search :search-client="searchClient" :index-name="masterIndex" :insights="true">
    <ais-configure :hitsPerPage="8" :ruleContexts="['support']" :get-ranking-info.camel="true" :facetFilters="[
    'type:Support'
  ]" />

    <div class="pt-24 pb-16 -mt-8 bg-gray-200">
      <div class="container px-2">
        <h1 class="page_title text-center font-semibold uppercase mt-4">Support Center</h1>
        <p class="text-center text-xl mb-5">Find the answer to your question by using our indepth knowledge
          base.
        </p>

        <ais-search-box v-model="query"
          class="search_page__search_form max-w-md text-center mx-auto my-6 mb-2"
          placeholder="Search knowledge base">
          <template v-slot:submit-icon><svg-icon name="search-line"></svg-icon></template>
          <template v-slot:reset-icon><svg-icon name="close-line"></svg-icon></template>
        </ais-search-box>

        <div class="max-w-md mx-auto mb-4 flex justify-between">
          <ais-stats class=" mx-auto"></ais-stats>
        </div>
      </div>
    </div>

    <div v-if="query" class="py-12 bg-gray-100">
      <ais-state-results class="max-w-3xl w-5/6 mx-auto">
        <template v-slot="{ state: { query }, results: { hits } }">
          <ais-hits v-if="hits.length > 0">
            <template v-slot="{ items }" class="my-6">
              <ResultBase v-for="item in items" :result="item" :key="item.objectID" />
            </template>
          </ais-hits>
          <p v-else
            class="text-center my-6 border border-gray-200 bg-white rounded-md p-4 text-lg shadow">
            There
            are no results found for: "{{ query }}".</p>

          <ais-pagination class="mb-8" :padding="5" v-if="hits.length > 0" />
        </template>
      </ais-state-results>
    </div>
  </ais-instant-search>
</template>

<script>
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, MASTER_INDEX } from '@js/config/algolia.js';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import {
  AisInstantSearch,
  AisConfigure,
  AisStats,
  AisSearchBox,
  AisHits,
  AisStateResults,
  AisPagination,
} from 'vue-instantsearch/vue3/es'

import ResultBase from '@js/components/search/results/ResultBase.vue'

export default {
  data() {
    return {
      searchClient: algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY),
      masterIndex: MASTER_INDEX,
      query: ''
    }
  },

  components: {
    ResultBase,
    AisInstantSearch,
    AisConfigure,
    AisStats,

    AisSearchBox,
    AisHits,
    AisStateResults,
    AisPagination,
  }
}
</script>
