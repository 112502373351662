<template>
  <ol v-if="state" class="pl-0 list-none">
    <li v-for="hit in state.hits" :key="hit.objectID">
      <slot name="item" :item="hit"> </slot>
    </li>
    <li class="sentinel" ref="trigger" />
  </ol>

  <a v-if="state?.hits?.length > 20" href="#" aria-label="Back to top of page" title="Back to top"
    class="underline fixed bottom-5 right-5 z-10 text-gray-700 bg-white border-2 border-gray-300 rounded-full p-3 opacity-90 hover:text-gray-800 hover:border-teal-500 focus:ring focus:ring-teal-500">
    <svg-icon name="arrow-up-line" class="w-10 h-10"></svg-icon>
  </a>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';
import SvgIcon from '@js/components/SvgIcon.vue'

let observer = null;

export default {
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],

  updated() {
    if (!observer && this.state.hits.length) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !this.state.isLastPage) {
            this.state.showMore();
          }
        })
      }, { threshold: 0 })

      observer.observe(this.$refs.trigger)
    }
  },
};
</script>
