<template>
  <DefaultResult :result="result" :highlight="true">
    <template v-slot>
      <a :href="result.url"
        class="group relative flex items-start no-underline space-x-4 p-6 lg:space-x-6">
        <figure v-if="result.poster" class="w-1/3 shrink-0 border border-gray-200 rounded-md">
          <img :src="result.poster" alt="" class="rounded-md">
        </figure>
        <div class="self-center">
          <h3 class="text-2xl text-blue-700 group-hover:underline">
            <ais-highlight :hit="result" attribute="title" highlightedTagName="mark"></ais-highlight>
          </h3>
          <p class="mb-0 text-gray-700">
            <ais-snippet v-if="result.content" :hit="result" attribute="content">
            </ais-snippet>
          </p>
        </div>
      </a>
    </template>
  </DefaultResult>
</template>

<script>
import {
  AisHighlight,
  AisSnippet
} from 'vue-instantsearch/vue3/es'
import DefaultResult from '@js/components/search/results/DefaultResult.vue'
import SvgIcon from '@js/components/SvgIcon.vue'
import { SEARCH_ICONS } from '@js/config/algolia.js';

export default {
  props: ['result'],

  data() {
    return {
      icons: SEARCH_ICONS,
    }
  },

  components: {
    AisHighlight,
    AisSnippet,
    DefaultResult,
    SvgIcon
  },
}
</script>
