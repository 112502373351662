// Tippy for main menu
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

import { nextTick } from "vue";
import { emitter } from "@js/lib/eventBus.js";
import "@js/config/plugins"; // Misc JS plugins

// Plugins
import VueScrollTo from "vue-scrollto";
import { VueMasonryPlugin } from "vue-masonry";
import {
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from "@headlessui/vue";
import { VueRecaptcha } from "vue-recaptcha";

import SupportSearch from "@js/components/search/SupportSearch.vue";
import MiniSupportSearch from "@js/components/search/MiniSupportSearch.vue";
import AssetSearch from "@js/components/search/AssetSearch.vue";
import SearchPage from "@js/components/search/SearchPage.vue";
import AssetBrowser from "@js/components/AssetBrowser.vue";
import GembaForm from "@js/components/GembaForm.vue";
import VideoSlider from "@js/components/VideoSlider.vue";
import BlogSlider from "@js/components/BlogSlider.vue";
import TestimonialSlider from "@js/components/TestimonialSlider.vue";
import Alert from "@js/components/Alert.vue";
import BlogPromo from "@js/components/BlogPromo.vue";
import PlayList from "@js/components/PlayList.vue";
import Tabs from "@js/components/Tabs.vue";
import VideoModal from "@js/components/VideoModal.vue";

// Global Components
import Spinner from "@js/components/Spinner.vue";
import SvgIcon from "@js/components/SvgIcon.vue";

// Vue
import { createApp, configureCompat } from "vue";

window.tippy = tippy;

configureCompat({
  MODE: 3,
  RENDER_FUNCTION: false,
  ATTR_FALSE_VALUE: false,
  ATTR_ENUMERATED_COERCION: false
});

const app = createApp({
  delimiters: ["${", "}"],

  data() {
    return {
      mainMenuOpen: false,
      translateMenuOpen: false
    };
  },

  components: {
    SupportSearch,
    MiniSupportSearch,
    AssetSearch,
    SearchPage,
    AssetBrowser,
    GembaForm,
    VideoSlider,
    BlogSlider,
    Alert,
    TestimonialSlider,
    BlogPromo,
    PlayList,
    Tabs,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    VueRecaptcha,
    VideoModal
  }
});

// Add event bus
app.config.globalProperties.emitter = emitter;

// Vue Setup
app.use(VueScrollTo, {
  offset: -150
});
app.use(VueMasonryPlugin);

app.component("vue-simple-spinner", Spinner);
app.component("svg-icon", SvgIcon);

app.config.compilerOptions.whitespace = "preserve";
app.config.compilerOptions.isCustomElement = tag =>
  ["google-cast-launcher"].includes(tag);

app.mount("#app");

// JWPlayer needs to wait for the app to be mounted before it can initialize
nextTick(() => {
  window.dispatchEvent(new Event("vue-mounted"));
});
