<template>
  <div class="search_page bg-gray-100">

    <ais-instant-search :search-client="searchClient" :insights="true" :index-name="masterIndex" :routing="routing">
      <ais-configure :get-ranking-info.camel="true"></ais-configure>

      <div class="max-w-screen-xl mx-auto pt-12 px-8">
        <ais-search-box class="search_page__search_form mb-4" placeholder="Search Gemba Academy">
          <template v-slot:submit-icon><svg-icon name="search-line"></svg-icon></template>
          <template v-slot:reset-icon><svg-icon name="close-line"></svg-icon></template>
        </ais-search-box>

        <div class="flex justify-between flex-wrap">
          <ais-stats></ais-stats>
        </div>
      </div>

      <div class="page--sidebar max-md:flex-col-reverse">
        <article class="page--content">
          <ais-state-results>
            <template v-slot="{ state: { query }, results: { hits } }">
              <!-- Pagination useful for testing -->
              <!-- <ais-pagination class="mb-6" :padding="2" v-if="hits.length" v-on:page-change="scrollUp" /> -->
              <a v-if="loggedin" :href="`/asset-browser?s=${query}`"
                class="flex items-center no-underline mb-2 bg-blue-700 text-lg text-white hover:text-white hover:underline py-4 px-8 antialiased">
                <svg-icon name="file-download-fill" classes="mr-2 w-6 h-6"></svg-icon> Looking for supplemental
                course documents? Search our&nbsp;<span class="underline">asset browser</span>&nbsp;›
              </a>

              <app-infinite-hits v-if="hits.length">
                <template #item="{ item }">
                  <ResultBase :result="item" />
                </template>
              </app-infinite-hits>

              <div v-else>
                <p>There are no hits found for: "{{ query }}".</p>
              </div>
            </template>
          </ais-state-results>
        </article>

        <aside>
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              className="flex justify-items-center justify-between w-full mb-6 py-2 px-4 text-sm rounded-md bg-gray-50 border border-gray-200 text-left uppercase md:hidden">
              <span v-if="open">Clear Filters</span>
              <span v-else>Search Filters</span>
              <svg-icon name="equalizer-line" classes="w-5 h-5"></svg-icon>
            </DisclosureButton>
            <DisclosurePanel :unmount="false" class="md:!block">
              <ais-index :index-name="glossaryIndex">
                <ais-configure :hits-per-page.camel="1" :get-ranking-info.camel="true" :page="0">
                </ais-configure>
                <ais-hits>
                  <SidebarStructuredResult />
                </ais-hits>
              </ais-index>

              <ais-panel>
                <template v-slot:default>
                  <h6 class="refinement-list__header">Type</h6>
                  <ais-refinement-list attribute="type" :sort-by="['count:desc', 'name:asc']">
                    <template v-slot:item="{ item, refine }">
                      <label class="ais-RefinementList-label">
                        <input class="ais-RefinementList-checkbox" type="checkbox" :checked="item.isRefined"
                          :value="item.value" @change="refine(item.value)">
                        <span class="ais-RefinementList-labelText flex space-x-2">
                          <svg-icon :name="icons[item.label]" classes="w-5 h-5 text-gray-700"></svg-icon>
                          <span>{{ item.label }}</span>
                        </span>
                        <span class="ais-RefinementList-count">{{ item.count.toLocaleString() }}</span>
                      </label>
                    </template>
                  </ais-refinement-list>
                </template>
              </ais-panel>

              <ais-panel>
                <template v-slot:default>
                  <h6 class="refinement-list__header">Languages</h6>
                  <ais-refinement-list attribute="languages" :sort-by="['count:desc', 'name:asc']" :show-more="true">
                  </ais-refinement-list>
                </template>
              </ais-panel>
            </DisclosurePanel>
          </Disclosure>
        </aside>
      </div>

    </ais-instant-search>
  </div>
</template>

<script>
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, MASTER_INDEX, SEARCH_ICONS, GLOSSARY_INDEX } from '@js/config/algolia.js';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import AppInfiniteHits from './InfiniteHits.vue'

import {
  AisInstantSearch,
  AisStats,
  AisSearchBox,
  AisStateResults,
  AisPagination,
  AisRefinementList,
  AisPanel,
  AisCurrentRefinements,
  AisConfigure,
  AisIndex,
  AisHits
} from 'vue-instantsearch/vue3/es'
import { history } from 'instantsearch.js/es/lib/routers';
import { singleIndex as singleIndexMapping } from 'instantsearch.js/es/lib/stateMappings';
import ResultBase from '@js/components/search/results/ResultBase.vue';
import SidebarStructuredResult from '@js/components/search/results/SidebarStructured.vue';
import SvgIcon from '@js/components/SvgIcon.vue'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'
export default {

  data() {
    return {
      searchClient: algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY),
      masterIndex: MASTER_INDEX,
      routing: {
        router: history(),
        stateMapping: singleIndexMapping(MASTER_INDEX),
      },
      icons: SEARCH_ICONS,
      glossaryIndex: GLOSSARY_INDEX
    }
  },

  props: {
    loggedin: {
      type: Boolean
    }
  },

  components: {
    ResultBase,
    SidebarStructuredResult,
    AisInstantSearch,
    AisStats,
    AisSearchBox,
    AisStateResults,
    AisPagination,
    AisRefinementList,
    AisPanel,
    AisCurrentRefinements,
    AisConfigure,
    AppInfiniteHits,
    AisIndex,
    AisHits,
    SvgIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },

  methods: {
    scrollUp() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>
