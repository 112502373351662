<template>
  <button @click="setIsOpen(true)">
    <slot>Play</slot>
  </button>

  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-50" :open="isOpen" @close="setIsOpen" :initialFocus="completeButtonRef">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0"
        enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100"
        leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-md bg-white px-3 pb-3 pt-4 text-left shadow-xl transition-all w-full sm:my-8 sm:max-w-6xl sm:p-5">
              <div class="mb-2">
                <button ref="closeBtn" class="group flex items-center ml-auto" type="button"
                  @click="setIsOpen(false)">
                  <span class="uppercase text-sm">Close</span>
                  <svg class="w-8 h-6 ml-1.5 rounded-sm group-focus:ring-1" aria-hidden="true">
                    <use fill="currentColor" xlink:href="#close-line"></use>
                  </svg>
                </button>
              </div>
              <div>
                <div id="video-modal-player" ref="playerEl"></div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { onMounted, ref, watch, nextTick } from 'vue'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
  TransitionChild, TransitionRoot
} from '@headlessui/vue'

const props = defineProps({
  videoId: String
})

const video = ref(null);
const isOpen = ref(false);
const player = ref(null);
const playerEl = ref(null);
const closeBtn = ref(null);

function setIsOpen(value) {
  isOpen.value = value
}

async function fetchData() {
  const url = `https://content.jwplatform.com/v2/media/${props.videoId}?format=json&poster_width=1920`
  try {
    const response = await fetch(url)
    video.value = await response.json()
  } catch (error) {
    console.log(error);
  }
}

function setupPlayer() {
  if (video.value) {
    jwplayer.key = 'd1/+jYQJeQh9E4AxT4ZcTq2/kVsLA0eSXwOMG5vhyR5M0uOX';
    player.value = jwplayer(playerEl.value);

    player.value.setup({
      autostart: true,
      playlist: video.value.playlist,
      width: "100%",
      aspectratio: "16:9",
      playbackRateControls: [0.85, 1, 1.25, 1.5, 1.75, 2],
      skin: {
        "controlbar": { "iconsActive": "#05B284" },
        "menus": { "textActive": "#05B284" }
      }
    })
  }
}

onMounted(() => {
  fetchData();
})

watch(isOpen, () => {
  if (isOpen.value) {
    nextTick(() => {
      setupPlayer()
    })
  }
})
</script>
