<template>
  <div class="mt-4">
    <transition name="fade_down" mode="out-in">
      <div v-if="files.length || modulefiles.length">
        <p>
          <button @click="selectAll = !selectAll" class="block">Select/Deselect All</button>
        </p>

        <ul v-if="modulefiles.length" class="flex flex-wrap mb-4 border-b border-grey-lighter pb-2">
          <asset-browser-file v-for="file in modulefiles" :key="file.id" :file="file" :checked="selectAll" />
        </ul>

        <ul v-if="files.length" class="flex flex-wrap">
          <asset-browser-file v-for="file in uniqueFiles" :key="file.id" :file="file" :checked="selectAll" />
        </ul>
      </div>

      <p v-else-if="message" class="border border-yellow-400 bg-yellow-100 p-2 text-lg">{{ message }}</p>

      <vue-simple-spinner v-else message="Retrieving File List" size="medium" line-bg-color="#eee"
        line-fg-color="#05b284" />
    </transition>
  </div>
</template>

<script>
import AssetBrowserFile from './AssetBrowserFile.vue'

export default {
  name: 'assetBrowserFiles',

  props: {
    module: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      files: [],
      modulefiles: [],
      message: null,
      selectAll: false
    }
  },

  computed: {
    // filter out duplicate files
    uniqueFiles() {
      let fileIds = new Set();
      return this.files.filter((file) => {
        if (fileIds.has(file.id)) {
          return false
        }

        fileIds.add(file.id)
        return true
      })
    }
  },

  components: {
    AssetBrowserFile
  },

  mounted() {
    fetch(`/api/v1/school/module/${this.module.id}?` + new URLSearchParams({
      include: ['files', 'modulefiles']
    }))
      .then(response => {
        if (response.ok) return response.json();

        throw new Error("There was an error retrieving the file list.");
      })
      .then(successResp => {
        this.files = successResp.files.data
        this.modulefiles = successResp.modulefiles.data

        if (this.files.length < 1 && this.modulefiles.length < 1) {
          this.message = "No files were found."
        }
      })
      .catch(error => {
        this.message = error
      });
  }
}
</script>
