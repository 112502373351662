<template>
  <transition name="fade">
    <div v-if="show" class="relative">
      <slot></slot>

      <button @click="dismissNotification" v-if="dismissible"
        class="absolute right-0 top-0 mr-4 mt-4 text-grey-dark hover:text-grey-darkest">
        <svg-icon name="close-circle-line"></svg-icon>
      </button>
    </div>
  </transition>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  props: {
    uid: {
      type: String,
      required: true
    },
    dismissible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: true
    }
  },

  computed: {
    cookieName() {
      return `gb-${this.uid}`
    }
  },

  created() {
    const cookie = Cookies.getJSON(this.cookieName);
    this.show = cookie === undefined ? true : cookie
  },

  methods: {
    dismissNotification() {
      this.show = false;
      Cookies.set(this.cookieName, false, { expires: 7 })
    }
  }
}
</script>
