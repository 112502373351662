<template>
  <div
    class="relative mb-8 bg-white rounded-md overflow-hidden shadow transition-shadow hover:shadow-lg"
    :class="{ 'border-2 border-teal-500': result?._rankingInfo?.promoted }">

    <p class="border-b border-gray-200 text-gray-700 text-xs uppercase font-semibold mb-0 px-6 py-2 flex items-center"
      :class="{
      'bg-teal-500 text-white': result?._rankingInfo?.promoted,
      'bg-gray-200 border-gray-300': highlight
    }">
      <svgIcon :name="icons[result.type]" classes="w-6 h-6 mr-2" />
      <span v-if="result?._rankingInfo?.promoted">Featured {{ contentTitles[result.type] }}</span>
      <span v-else>{{ contentTitles[result.type] }}</span>
    </p>

    <slot>
      <slot name="header">
        <h3 class="px-6 text-3xl pt-2">
          <a :href="result.url" class="" :class="{ 'text-teal-600': result?._rankingInfo?.promoted }">

            <ais-highlight :hit="result" attribute="title" highlightedTagName="mark"></ais-highlight>
          </a>
        </h3>
      </slot>

      <slot name="content">
        <section class="mb-0 mt-4 pb-8 px-6 flex space-x-4">
          <div v-if="result?.images?.search_thumbnail" class="shrink-0 w-1/5">
            <img :src="result?.images?.search_thumbnail?.url" alt="" :width="result?.images?.search_thumbnail?.width"
              :height="result?.images?.search_thumbnail?.height">
          </div>
          <ais-snippet v-if="result.content" :hit="result" attribute="content" highlightedTagName="mark">
          </ais-snippet>
        </section>
      </slot>

      <slot name="footer">
        <p v-if="formattedDate" class="bg-gray-200 px-6 py-3 text-sm mb-0">{{ formattedDate }}</p>
      </slot>
    </slot>
  </div>
</template>

<script>
import {
  AisHighlight,
  AisSnippet
} from 'vue-instantsearch/vue3/es'
import SvgIcon from '@js/components/SvgIcon.vue'
import { SEARCH_ICONS } from '@js/config/algolia.js';

export default {
  props: ['result', 'highlight'],

  data() {
    return {
      icons: SEARCH_ICONS,
      contentTitles: {
        'Course': 'Course',
        'Video': 'Video',
        'Blog': 'Article',
        'Podcast': 'Podcast',
        'Support': 'Support',
        'Gemba Insights': 'Gemba Insights',
      }
    }
  },

  components: {
    AisHighlight,
    AisSnippet,
    SvgIcon
  },

  computed: {
    formattedDate() {
      return new Date(this.result?.dateUpdated * 1000).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })
    }
  }
}
</script>
