const indexEnvMap = {
  production: "prod_searchable_posts"
  // development: "dev_index"
};

export const ALGOLIA_APP_ID = "QJ5DUSBOKE";
export const ALGOLIA_SEARCH_KEY = "b10f21e45107716f1d88f8a134b1e50a";
export const MASTER_INDEX =
  indexEnvMap[process.env.NODE_ENV] ?? "prod_searchable_posts";
export const ASSET_INDEX =
  process.env.NODE_ENV == "production" ? "prod_ASSETS" : "test_ASSETS";
export const GLOSSARY_INDEX =
  process.env.NODE_ENV == "production" ? "prod_GLOSSARY" : "test_GLOSSARY";

export const SEARCH_ICONS = {
  Blog: "article-line",
  Podcast: "headphone-fill",
  Course: "folders-fill",
  Support: "information-fill",
  Video: "play-circle-fill",
  Other: "file-3-line",
  "Gemba Insights": "lightbulb-fill"
};
