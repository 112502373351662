<template>
  <li class="wide_module">
    <div @click="open = !open" class="header cursor-pointer">
      <h3 class="title">{{ module.title }}</h3>
      <div class="meta">
        <p>
          <span v-if="fileCount">{{ fileCount }} Selected</span>
          <span v-else-if="!fileCount && open" class="text-3xl leading-none">-</span>
          <span v-else class="text-3xl leading-none">+</span>
        </p>
      </div>
    </div>

    <transition name="fade_down" mode="out-in">
      <keep-alive>
        <asset-browser-files @updateFileCount="updateFileCount" :module="module" v-if="open" />
      </keep-alive>
    </transition>
  </li>
</template>

<script>
import AssetBrowserFiles from './AssetBrowserFiles.vue'

export default {
  name: 'assetBrowserModule',

  props: {
    module: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      open: false,
      fileCount: 0
    }
  },

  components: {
    AssetBrowserFiles
  },

  methods: {
    updateFileCount(count) {
      this.fileCount = this.fileCount + count
    }
  }
}
</script>

<style>
.fade_down-enter-active,
.fade_down-leave-active {
  transition:
    opacity 0.3s cubic-bezier(0.21, 0, 0.36, 1),
    transform 0.3s cubic-bezier(0.21, 0, 0.36, 1);
}

.fade_down-enter,
.fade_down-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
