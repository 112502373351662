<template>
  <div v-if="show" class="blog_promo w-full">
    <button @click="close" class="blog_promo--close btn--plain">
      <svg width="29" height="29" viewBox="0 0 29 29">
        <path
          d="M20.13 8.11l-5.61 5.61-5.609-5.61-.801.801 5.61 5.61-5.61 5.61.801.8 5.61-5.609 5.61 5.61.8-.801-5.609-5.61 5.61-5.61"
          fill-rule="evenodd"></path>
      </svg>
    </button>

    <slot></slot>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  props: {
    promoId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      show: true
    }
  },

  created() {
    if (Cookies.get('blog_promo-' + this.promoId)) {
      this.show = false;
    }
  },

  methods: {
    close() {
      this.setCookie();
      this.hidePromo();
    },

    setCookie() {
      Cookies.set('blog_promo-' + this.promoId, 'hide', { expires: 30 });
    },

    hidePromo() {
      this.show = false;
    }
  }
}
</script>
