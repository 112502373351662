<template>
  <li class="w-full sm:w-1/2 md:w-1/3 pr-2 mb-1">
    <label class="flex items-center">
      <input :disabled="!file.bucket" type="checkbox" :name="file.id" v-model="selected"
        class="form-checkbox h-4 w-4">

      <a :href="file.url" class="inline-flex items-center ml-2" download>
        <svg-icon :name="iconClass"></svg-icon> <span class="ml-1">{{ file.title }}</span>
      </a>
    </label>
  </li>
</template>

<script>
export default {
  name: 'assetBrowserFile',

  data() {
    return {
      selected: this.checked
    }
  },

  props: {
    file: {
      required: true
    },

    checked: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    checked: function (val, oldVal) {
      this.selected = val
    },
    selected: function (val) {
      this.selectFile(val, this.file)
    }
  },

  computed: {
    iconClass() {
      switch (this.file.kind) {
        case "pdf":
          return "file-pdf-line"
          break;
        case "excel":
          return "file-excel-line"
          break;
        case "text":
          return "file-text-line"
          break;
        case "word":
          return "file-word-line"
          break;
        default:
          return "file-line"
          break;
      }
    }
  },

  methods: {
    selectFile(checked) {
      // don't select files that aren't in S3, they can't be zipped
      if (this.file.bucket) {
        // update module count
        this.$emit('updateFileCount', checked ? 1 : -1);

        // update main file list
        this.emitter.emit('selectfile', {
          checked: checked,
          file: this.file
        });
      }
    }
  }
}
</script>
