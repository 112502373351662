<template>
  <ais-instant-search :search-client="searchClient" :index-name="masterIndex">
    <ais-configure :hitsPerPage="8" :ruleContexts="['support']" :facetFilters="[
    'section:Support'
  ]" />

    <p class="flex items-start font-semibold antialiased text-xl space-x-3 mb-5">
      <svg-icon name="support-light" classes="w-16 h-16"></svg-icon>
      <span>Find the answer to your question by using our indepth knowledge base.</span>
    </p>

    <ais-search-box v-model="query" class="search_form border-teal-500 mt-6 mb-2"
      placeholder="Search knowledge base" />

    <div class="max-w-md mx-auto mb-4 flex justify-end">
      <ais-powered-by></ais-powered-by>
    </div>

    <div v-if="query" class="mt-2">
      <ais-state-results class="">
        <template v-slot="{ state: { query }, results: { hits } }">
          <ais-hits v-if="hits.length > 0">
            <template v-slot="{ items }" class="">
              <div v-for="item in items" :key="item.objectID">
                <h3 class="font-normal text-lg">
                  <a :href="item.url" class="text-teal-600 flex items-center space-x-2">
                    <svg-icon name="arrow-right-circle-fill" classes="text-teal-600 w-6 h-6">
                    </svg-icon>
                    <span>{{ item.title }}</span>
                  </a>
                </h3>
              </div>
            </template>
          </ais-hits>
          <p v-else
            class="text-gray-800 bg-gray-200 p-1 border-b text-lg flex items-center space-x-2">
            <svg-icon name="alert-fill" classes="w-6 h-6 text-yellow-400"></svg-icon>
            <span>There are no results found for: "{{ query }}".</span>
          </p>
        </template>
      </ais-state-results>
    </div>
  </ais-instant-search>
</template>

<script>
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, MASTER_INDEX } from '@js/config/algolia.js';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import {
  AisInstantSearch,
  AisConfigure,
  AisPoweredBy,
  AisSearchBox,
  AisHits,
  AisStateResults,
  AisPagination,
} from 'vue-instantsearch/vue3/es'

export default {
  data() {
    return {
      searchClient: algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY),
      masterIndex: MASTER_INDEX,
      query: ''
    }
  },

  components: {
    AisInstantSearch,
    AisConfigure,
    AisPoweredBy,
    AisSearchBox,
    AisHits,
    AisStateResults,
    AisPagination,
  }
}
</script>

<style>
.search_form {
  @apply mb-1;

  .ais-SearchBox-form {
    display: flex;
    position: relative;
    gap: 0.25rem;
  }

  .ais-SearchBox-input {
    @apply bg-white border border-gray-200 text-lg w-full rounded;

    &:focus {
      @apply border-teal-500 shadow-none;
    }
  }

  .ais-SearchBox-submit {
    @apply grid place-content-center flex-shrink-0 bg-teal-500 text-white w-12 border border-teal-500 rounded;

    path {
      fill: white;
    }
  }

  .ais-SearchBox-reset {
    position: absolute;
    right: 4rem;
    top: 18px;
    @apply antialiased;

    path {
      @apply fill-gray-700;
    }
  }

  .ais-SearchBox-submitIcon {
    width: 18px;
    height: 18px;
  }

  .ais-SearchBox-resetIcon {
    width: 14px;
    height: 14px;
  }
}
</style>
