<template>
  <slot :selectedTab="selectedTab" :overflown="overflown" :changeTab="changeTab" :scrollTabs="scrollTabs"></slot>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

const selectedTab = ref(0)
const overflown = ref(false)
let tabList = null

function changeTab(event) {
  if (event.target) {
    selectedTab.value = event.target.selectedIndex
  } else {
    selectedTab.value = event
  }

}

function isOverflown({ clientWidth, clientHeight, scrollWidth, scrollHeight }) {
  overflown.value = scrollHeight > clientHeight || scrollWidth > clientWidth;
}

function scrollTabs() {
  tabList.scrollTo(9999, 0)
}

onMounted(() => {
  tabList = document.querySelector('.tab-list')
  isOverflown(tabList)

  window.addEventListener('resize', () => {
    isOverflown(tabList)
  })
})
</script>
