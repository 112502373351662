<template>
  <section class="bg-gray-200 py-10" name="Video Slider">
    <header class="flex mx-6 mb-6 justify-between items-end">
      <div>
        <slot name="header"></slot>
      </div>
      <div class="swiper-pagination"></div>
    </header>

    <div class="swiper" ref="slider">
      <div class="swiper-wrapper">
        <slot></slot>
      </div>

      <div class="swiper-button-next">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44">
          <path d="M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z" />
        </svg>
      </div>

      <div class="swiper-button-prev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44">
          <path d="M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z" />
        </svg>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  mounted() {
    new Swiper(this.$refs.slider, {
      modules: [Navigation, Pagination],
      slidesPerView: 4.25,
      spaceBetween: 32,
      grabCursor: true,
      pagination: {
        el: this.$el.querySelector('.swiper-pagination'),
        type: 'fraction',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is <= 700px
        700: {
          slidesPerView: 1.25,
          spaceBetween: 24
        },
        // when window width is <= 900px
        1100: {
          slidesPerView: 2.25
        },
        // when window width is <= 1500px
        1500: {
          slidesPerView: 3.25
        },
      }
    })
  }
}
</script>

<style lang="postcss" scoped>
.swiper {
  width: 100%;
  height: 100%;
  @apply px-6;
}

.swiper-button-next,
.swiper-button-prev {
  fill: #000;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
  width: 45px;
  height: 55px;
  margin-top: -27px;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.25),
    0 2px 15px rgba(0, 0, 0, 0.2);

  svg {
    width: 15px;
  }
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}

.swiper-slide {
  height: auto;
}

.swiper-pagination {
  position: static;
  width: auto;
  margin: 0;
}

.swiper-pagination-fraction {
  @apply flex bg-white text-gray-600 text-sm border-t border-gray-300 rounded py-2 px-3;
}

.swiper-pagination-fraction :deep(.swiper-pagination-current) {
  @apply mr-1;
}

.swiper-pagination-fraction :deep(.swiper-pagination-total) {
  @apply ml-1;
}
</style>
