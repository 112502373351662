<template>
  <DefaultResult :result="result" :highlight="true">

    <template v-slot>
      <a :href="url"
        class="group relative flex items-start no-underline space-x-4 p-6 lg:space-x-6">
        <figure class="w-1/3 shrink-0 border border-gray-200 rounded-md">
          <img :src="result.poster" alt="" class="rounded-md">
        </figure>
        <div class="self-center">
          <h3 class="text-2xl text-blue-700 group-hover:underline">
            <ais-highlight :hit="result" attribute="title" highlightedTagName="mark"></ais-highlight>
          </h3>
          <p class="mb-0 text-gray-700">
            <ais-snippet v-if="result.content" :hit="result" attribute="content">
            </ais-snippet>
          </p>
        </div>
      </a>

      <div v-if="result.courses && result.courses.length"
        class="rounded-b-md bg-gray-50 p-6 border-t border-gray-300">
        <p class="uppercase text-sm font-semibold text-teal-600 mb-2">Find this video in:</p>
        <ul class="list-none m-0 p-0 space-y-3 divide-y divide-gray-300">
          <li v-for="course in result.courses" :key="course.url" class="pt-3">
            <a class="group w-full no-underline text-gray-800 transition-colors duration-300 focus:outline-none focus:ring focus:ring-teal-600"
              :href="courseUrl(course)">
              <h4 class="text-lg font-semibold flex items-center space-x-2">
                <svgIcon name="folders-fill" classes="text-teal-600 w-6 h-6 shrink-0" />
                <span class="underline group-hover:text-teal-600">{{ course.title }}</span>
                <span aria-hidden="true"
                  class="max-md:hidden !ml-auto bg-gray-200 rounded-full uppercase text-teal-600 text-xs px-2.5 py-1 -translate-y-2 opacity-0 transition-all duration-200 group-hover:opacity-100 group-hover:translate-y-0">
                  View Course
                </span>
              </h4>
              <p class="mb-0">{{ course.description }}</p>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </DefaultResult>
</template>

<script>
import {
  AisHighlight,
  AisSnippet
} from 'vue-instantsearch/vue3/es'
import DefaultResult from '@js/components/search/results/DefaultResult.vue'
import SvgIcon from '@js/components/SvgIcon.vue'
import { SEARCH_ICONS } from '@js/config/algolia.js';

export default {
  props: ['result'],

  data() {
    return {
      icons: SEARCH_ICONS,
    }
  },

  components: {
    AisHighlight,
    AisSnippet,
    DefaultResult,
    SvgIcon
  },

  computed: {
    url() {
      return this.result.single_url || this.result.courses[0].video_url
    }
  },

  methods: {
    courseUrl(course) {
      return this.result.courses.length > 1 ? course.video_url : course.url
    }
  }
}
</script>
