<template>
  <ais-instant-search :search-client="searchClient" :index-name="assetIndex">
    <ais-configure :hitsPerPage="12" />
    <div class="asset_browser_index__intro">

      <slot name="breadcrumbs"></slot>

      <div>
        <h1 class="page_title text-white text-center font-semibold uppercase mt-4">Asset Browser</h1>
        <p class="text-white text-center text-xl mb-5">Search for specific assets below or select the school
          below for a complete listing.</p>

        <ais-search-box v-model="query" class="search_page__search_form max-w-md mx-auto mt-6 mb-2"
          placeholder="Search Assets" />

        <div class="text-white max-w-md mx-auto mb-4 flex justify-between">
          <ais-stats v-if="query"></ais-stats>
          <ais-powered-by theme="dark"></ais-powered-by>
        </div>
      </div>
    </div>

    <div v-if="query" class="page--container">
      <nav class="mt-6 w-5/6 mx-auto flex items-center flex-wrap">
        <p class="mb-0 mr-2">Filter by file type:</p>
        <ais-refinement-list attribute="kind" :sort-by="['count:desc']" :class-names="{
    'ais-RefinementList': 'inline_refinement_list',
  }" />
      </nav>

      <ais-state-results class="bg-white w-5/6 mx-auto">
        <template v-slot="{ state: { query }, results: { hits } }">
          <ais-hits v-if="hits.length > 0">
            <template v-slot="{ items }" class="my-6">
              <div v-for="item in items" :key="item.objectID">
                <div class="search_result">
                  <h3 class="search_result--title">
                    <a :href="'/asset-download?id=' + item.assetId">
                      <ais-highlight :hit="item" attribute="title"></ais-highlight>
                    </a>
                  </h3>
                  <p v-if="item.related.length" class="mb-0">
                    Located in: <a v-for="related in item.related" :key="related.url" :href="related.url"><em
                        v-html="related.title"></em></a>
                  </p>
                </div>
              </div>
            </template>
          </ais-hits>
          <p v-else class="text-center my-6 border border-yellow-400 bg-yellow-100 p-4 text-lg">
            There are no
            results found for: "{{ query }}".</p>

          <ais-pagination class="mb-8" :padding="5" v-if="hits.length > 0" />
        </template>
      </ais-state-results>
    </div>
  </ais-instant-search>
</template>

<script>
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, ASSET_INDEX } from '@js/config/algolia.js';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import {
  AisInstantSearch,
  AisConfigure,
  AisStats,
  AisPoweredBy,
  AisSearchBox,
  AisHits,
  AisHighlight,
  AisStateResults,
  AisPagination,
  AisRefinementList,
} from 'vue-instantsearch/vue3/es'

export default {

  props: {
    searchQuery: {
      required: false,
      type: String,
      default: ''
    }
  },

  components: {
    AisInstantSearch,
    AisConfigure,
    AisStats,
    AisPoweredBy,
    AisSearchBox,
    AisHits,
    AisHighlight,
    AisStateResults,
    AisPagination,
    AisRefinementList,
  },

  data() {
    return {
      searchClient: algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY),
      assetIndex: ASSET_INDEX,
      query: this.searchQuery
    }
  }
}
</script>
