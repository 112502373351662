<template>
  <div v-if="result" class="relative mb-8 bg-white rounded-md shadow ">
    <p class="bg-teal-500 px-4 py-2 rounded-t-md text-white mb-0 ">
      Gemba Glossary
    </p>

    <h2 class="pt-2 px-6 text-2xl">
      {{ result.term }}
    </h2>

    <section class="mb-0 mt-4 pb-6 px-6 prose prose-sm">
      <div v-html="result.definition" class=""></div>

      <ul v-if="filteredLinks.length > 0" class="mt-4">
        <li v-for="el in filteredLinks" :key="el.url">
          <a :href="el.url" :target="el.target" :aria-label="el.ariaLabel">
            {{ el.text }}
          </a>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import {
  createWidgetMixin
} from 'vue-instantsearch/vue3/es'
import { connectHits } from 'instantsearch.js/es/connectors';

export default {

  mixins: [createWidgetMixin({ connector: connectHits })],

  computed: {
    result() {
      if (!this.state) return undefined;
      const result = this.state.hits[0];

      if (
        result &&
        result._rankingInfo.words - 1 === result._rankingInfo.proximityDistance
      ) {
        return result;
      }
      return undefined;
    },

    filteredLinks() {
      if (!this.result) return [];

      return this.result.seeAlso.map((el) => {
        const linkItem = el.linkItem;
        if (linkItem && !linkItem.url) return;

        return {
          ...linkItem,
        }
      })
    }
  }
}
</script>
