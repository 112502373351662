<template>
  <div>
    <h1>{{ school.title }}</h1>
    <p v-if="school.title" class="mb-6 sm:w-4/5">You can expand any course below by clicking on it's title.
      Clicking a filename will let you download files individualy or alternatively you can click the checkbox to add the
      files to a queue and download them all at once.</p>

    <transition name="fade">
      <p v-if="successMessage" class="border border-teal-700 bg-teal-500 p-2 text-lg text-white"
        v-html="successMessage">
      </p>
    </transition>
    <transition name="fade">
      <p v-if="errorMessage" class="border border-red-900 bg-red-700 p-2 text-lg text-white">
        There was an error downloading your files: {{ errorMessage }}
      </p>
    </transition>

    <transition name="fade">
      <div v-if="selectedFiles.length"
        class="fixed z-50 bottom-0 left-0 text-white bg-teal-600 border-t border-teal-700 w-full p-4">
        <div class="max-w-6xl mx-auto flex flex-wrap items-center justify-between">
          <p v-if="!downloading" class="mb-0">{{ selectedFiles.length }} Total Files.</p>
          <p v-if="downloading" class="mb-0">Your files are being prepared. This can take up to several minutes. Your
            download will automatically begin when it is ready.</p>

          <button @click.prevent="downloadFiles" class="btn bg-teal-700">
            <span v-if="!downloading">Download All Files</span>
            <vue-simple-spinner v-else size="small" line-bg-color="#fff" line-fg-color="#5ca0ea" />
          </button>

          <vue-simple-progress v-if="downloading" :val="progress" bar-color="#5ca0ea" class="mt-2 mb-4 w-full" />
        </div>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <ul v-if="school.modules.data.length" class="video_modules">
        <asset-browser-module v-for="module in filteredSchool" :key="module.id" :module="module" />
      </ul>

      <vue-simple-spinner v-else message="Loading..." size="large" line-bg-color="#eee" line-fg-color="#05b284" />
    </transition>
  </div>
</template>

<script>
import AssetBrowserModule from './AssetBrowserModule.vue'
import VueSimpleProgress from '@js/components/Progress.vue'
import zipAssetFiles from '@js/lib/zipAssetFiles.js'

let progressInterval = null

export default {
  name: 'assetBrowser',

  props: {
    schoolslug: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      downloading: false,
      successMessage: null,
      errorMessage: null,
      school: {
        modules: {
          data: []
        }
      },
      selectedFiles: [],
      progress: 5
    }
  },

  computed: {
    // Filters out modules with children to avoid duplicate downloads
    filteredSchool() {
      if (!this.school.modules || !this.school.modules.data) {
        return []
      }

      return this.school.modules.data.filter(module => !module.hasDescendants);
    }
  },

  components: {
    AssetBrowserModule,
    VueSimpleProgress
  },

  created() {
    fetch(`/api/v1/school/${this.schoolslug}?include=modules`)
      .then(response => response.json())
      .then(data => this.school = data);
  },

  mounted() {
    this.emitter.on('selectfile', this.onSelectFile);
    this.emitter.on('zipFilesError', this.downloadError);
    this.emitter.on('zipFilesSuccess', this.downloadSuccess);
  },

  beforeUnmount() {
    this.emitter.off('selectfile', this.onSelectFile);
    this.emitter.off('zipFilesError', this.downloadError);
    this.emitter.off('zipFilesSuccess', this.downloadSuccess);
  },

  methods: {
    // addToArray is a boolean
    onSelectFile(obj) {
      if (obj.checked) {
        this.selectedFiles.push(obj.file)
      } else {
        const idx = this.selectedFiles.indexOf(obj.file)

        if (idx > -1) {
          this.selectedFiles.splice(idx, 1)
        }
      }
    },

    downloadFiles() {
      this.downloading = true
      this.errorMessage = null
      this.successMessage = null
      this.progress = 5 // reset

      let files = this.selectedFiles.map((file) => {
        return file.folder + file.filename
      })

      zipAssetFiles.zipFiles(files)

      // fake progress bar
      progressInterval = setInterval(() => {
        this.progress = this.progress + Math.ceil(Math.random() * 4)

        if (this.progress >= 85) {
          clearInterval(progressInterval)
        }
      }, 2500)
    },

    downloadError(err) {
      this.downloading = false
      this.errorMessage = err

      clearInterval(progressInterval)
    },

    downloadSuccess(fileUrl) {
      this.progress = 100
      clearInterval(progressInterval)

      // pause for fake progress bar
      setTimeout(() => {
        this.downloading = false
        this.errorMessage = null
        window.location = fileUrl
        this.successMessage = `Your files should start downloading immediately. If it doesn't <a class="text-white" href="${fileUrl}">click here</a>`
      }, 1500);
    },
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s cubic-bezier(0.21, 0, 0.36, 1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
