<template>
  <section class="flex-grow flex flex-col" :class="{ 'h-[450px]': !expanded && limitHeight }" name="Playlist">
    <div ref="playlist"
      class="scroll-snap-y overflow-y-scroll divide-y divide-gray-800 border border-gray-800 [scrollbar-width:thin] [scrollbar-color:#a0aec0_#1a202c]"
      :class="limitHeight ? 'rounded-t-lg' : 'rounded-lg'">
      <slot></slot>
    </div>

    <button v-if="limitHeight" @click="expanded = !expanded"
      class="text-gray-200 hover:text-white focus:text-white bg-gradient-to-r from-gray-800 to-gray-700 hover:from-gray-700 focus:from-gray-700 uppercase py-2 rounded-b-lg w-full flex items-center justify-center z-10 focus:outline-none focus:ring-2 focus:ring-teal-600">
      {{ expanded ? 'Collapse' : 'Show All Videos' }} <svg-icon
        :name="expanded ? 'arrow-up-s-line' : 'arrow-down-s-line'" />
    </button>
  </section>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
      limitHeight: false
    }
  },

  mounted() {
    if (this.$slots.default().length > 15) {
      this.limitHeight = true

      this.$nextTick(() => {
        const current = this.$el.querySelector('.active');
        this.scrollParentToChild(this.$refs.playlist, current)
      })
    }
  },

  methods: {
    scrollParentToChild(parent, child) {
      // Where is the parent on page
      const parentRect = parent.getBoundingClientRect();
      const parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth
      };

      // Where is the child
      const childRect = child.getBoundingClientRect();
      const isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

      // scroll child to the top
      const scrollTop = childRect.top - parentRect.top;
      parent.scrollTop += scrollTop;
      // if (!isViewable) {
      //     const scrollTop = childRect.top - parentRect.top;
      //     parent.scrollTop += scrollTop;
      // }
    },
  }
}
</script>
