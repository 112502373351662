<template>
  <slot :loading="loading" :success="success" :submissionErrors="submissionErrors" :submittable="submittable"
    :onSubmit="onSubmit" :onVerify="onVerify" :onExpired="onExpired" :country="country">
  </slot>
</template>

<script>
// import { VueRecaptcha } from 'vue-recaptcha';

export default {
  data() {
    return {
      formData: null,
      verified: false,
      loading: false,
      response: {
        success: false,
        errors: {}
      },
      country: {
        value: null,
      }
    }
  },

  // components: { VueRecaptcha },

  computed: {
    success() {
      return this.response['success'] || false
    },

    submissionErrors() {
      if (this.response['success'] == false) {
        return this.response['errors']
      }

      return [];
    },

    submittable() {
      return !this.loading && this.verified
    }
  },

  methods: {
    onSubmit(event) {
      this.formData = new FormData(event.target);
      const url = '/actions/' + this.formData.get('action');

      if (this.formData) {
        this.loading = true

        fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json, text-plain',
          },
          body: this.formData
        })
          .then(resp => { return resp.json() })
          .then((resp) => {
            // console.log('resp', resp)
            this.resetCaptcha();

            if (resp['success'] && resp.redirect) {
              window.location = resp.redirect
            } else {
              this.response = resp;
              this.loading = false
              window.scrollTo({ top: 0, behavior: 'smooth' });

              this.$nextTick(() => {
                let inputErrorTips = document.querySelectorAll('.input-error-tip');
                inputErrorTips.forEach(element => {
                  // Guard against tippy being initialized multiple times
                  if (element && element.hasOwnProperty('_tippy')) {
                    return;
                  }

                  if (element) {
                    tippy(element, {
                      content(reference) {
                        return reference.getAttribute('aria-label');
                      },
                      // offset: [0, 5],
                      // placement: 'bottom-start',
                    })
                  }
                });
              })
            }
          })
      }
    },

    onVerify(response) {
      this.verified = response
    },

    onExpired() {
      this.verified = false
    },

    resetCaptcha() {
      window.grecaptcha.reset()
    }
  }
}
</script>
