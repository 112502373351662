<template>
  <DefaultResult :result="result" :highlight="true">
    <template #footer>
      <div v-if="result.coursesCount || result.videosCount || result.totalTime"
        class=" bg-gray-800 px-6 py-3 border-t border-gray-300 flex flex-wrap items-center space-x-4">
        <p v-if="result.coursesCount"
          class="inline-flex items-center space-x-2 text-gray-200 hover:text-white focus:text-white font-semibold mb-0">
          <svg-icon name="folders-fill" classes="text-gray-500 w-6 h-6" />
          <span>{{ result.coursesCount }} Courses</span>
        </p>

        <p v-if="result.videosCount"
          class="inline-flex items-center space-x-2 text-gray-200 hover:text-white focus:text-white font-semibold mb-0">
          <svg-icon name="play-list-2-fill" classes="text-gray-500 w-6 h-6" />
          <span>{{ result.videosCount }} Videos</span>
        </p>

        <p v-if="result.totalTime"
          class="inline-flex items-center space-x-2 text-gray-200 hover:text-white focus:text-white font-semibold mb-0">
          <svg-icon name="time-line" classes="text-gray-500 w-6 h-6" />
          <span>{{ result.totalTime }}</span>
        </p>
      </div>
    </template>
  </DefaultResult>
</template>

<script>
import SvgIcon from '@js/components/SvgIcon.vue'
import { SEARCH_ICONS } from '@js/config/algolia.js';
import DefaultResult from '@js/components/search/results/DefaultResult.vue'


export default {
  props: ['result'],

  data() {
    return {
      icons: SEARCH_ICONS
    }
  },

  components: {
    SvgIcon,
    DefaultResult
  },
}
</script>
