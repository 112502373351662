import { InvokeCommand, LambdaClient } from "@aws-sdk/client-lambda";
import {
  CognitoIdentityClient,
  GetIdCommand,
  GetCredentialsForIdentityCommand
} from "@aws-sdk/client-cognito-identity";
import { emitter } from "@js/lib/eventBus.js";

const IDENTITY_POOL_ID = "us-east-1:634e1532-54e4-4427-8d73-9683e601ebd9";
const REGION = "us-east-1";
const BUCKET = "gemba-resource";
const FOLDER = "";

async function getCognitoCredentialsManually() {
  try {
    const identityClient = new CognitoIdentityClient({ region: REGION });

    // Manually fetch the Identity ID
    const getIdCommand = new GetIdCommand({
      IdentityPoolId: IDENTITY_POOL_ID
    });

    const identityResponse = await identityClient.send(getIdCommand);

    if (!identityResponse.IdentityId) {
      throw new Error("Failed to retrieve Identity ID");
    }

    const getCredentialsCommand = new GetCredentialsForIdentityCommand({
      IdentityId: identityResponse.IdentityId
    });

    const credentialsResponse = await identityClient.send(
      getCredentialsCommand
    );

    return credentialsResponse.Credentials;
  } catch (error) {
    console.error("Error fetching Cognito credentials manually:", error);
    return null;
  }
}

export default {
  async zipFiles(files) {
    const credentials = await getCognitoCredentialsManually();

    const client = new LambdaClient({
      region: REGION,
      credentials: {
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretKey,
        sessionToken: credentials.SessionToken
      }
    });

    const payload = JSON.stringify({
      region: REGION,
      bucket: BUCKET,
      folder: FOLDER,
      files: files
    });

    const command = new InvokeCommand({
      FunctionName: "zipS3Assets",
      Payload: payload
    });

    try {
      const { Payload } = await client.send(command);
      const result = new TextDecoder().decode(Payload);
      const response = JSON.parse(result);

      if (response.Location) {
        // successful response
        emitter.emit("zipFilesSuccess", response.Location);
      } else {
        console.log("Uncaught Error", files);
        // something went wrong
        emitter.emit(
          "zipFilesError",
          "An unknown error occurred downloading your files."
        );
      }
    } catch (error) {
      console.log("error: ", error);
      emitter.emit(
        "zipFilesError",
        "An unknown error occurred downloading your files."
      );
    }
  }
};
