<template>
  <section class="py-16" name="Testimonial Slider">
    <div class="swiper" ref="slider">
      <div class="swiper-wrapper">
        <slot></slot>
      </div>

      <div class="flex justify-center space-x-3 mt-8 pb-2">
        <button @click="prev"
          class="grid place-content-center w-10 h-10 rounded-full text-teal-500 bg-white border border-gray-400 hover:border-teal-500 focus:ring-1 focus:outline-none focus:ring-teal-500">
          <svg aria-hidden="true" class="w-6 h-6">
            <use fill="currentColor" xlink:href="#arrow-left-line"></use>
          </svg>
        </button>
        <button @click="next"
          class="grid place-content-center w-10 h-10 rounded-full text-teal-500 bg-white border border-gray-400 hover:border-teal-500 focus:ring-1 focus:outline-none focus:ring-teal-500">
          <svg aria-hidden="true" class="w-6 h-6">
            <use fill="currentColor" xlink:href="#arrow-right-line"></use>
          </svg>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Navigation } from 'swiper';
import 'swiper/css'

export default {
  mounted() {
    new Swiper(this.$refs.slider, {
      modules: [Navigation],
      slidesPerView: 1,
      grabCursor: true,
      autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    })
  },

  methods: {
    next() {
      this.$refs.slider.swiper.slideNext();
    },

    prev() {
      this.$refs.slider.swiper.slidePrev();
    }
  }
}
</script>

<style lang="postcss"></style>
