<template>
  <component :is="resultComponent" :result="result"></component>
</template>

<script>
import DefaultResult from '@js/components/search/results/DefaultResult.vue'
import Video from '@js/components/search/results/VideoResult.vue'
import Course from '@js/components/search/results/CourseResult.vue'
import GembaInsights from '@js/components/search/results/GembaInsightsResult.vue'

export default {
  props: ['result'],

  components: {
    DefaultResult,
    Video,
    Course,
    GembaInsights
  },

  computed: {
    // needs to be string?
    resultComponent() {
      const type = this.toPascalCase(this.result.type)
      return this.$options.components[type] ?? DefaultResult
    }
  },

  methods: {
    toPascalCase(str) {
      return str
        .trim()
        .split(/\s+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join('');
    }
  }
}
</script>
